// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-view-blog-blog-post-tsx": () => import("./../../../src/view/blog/BlogPost.tsx" /* webpackChunkName: "component---src-view-blog-blog-post-tsx" */),
  "component---src-view-blog-root-tsx": () => import("./../../../src/view/blog/Root.tsx" /* webpackChunkName: "component---src-view-blog-root-tsx" */),
  "component---src-view-faq-root-tsx": () => import("./../../../src/view/faq/Root.tsx" /* webpackChunkName: "component---src-view-faq-root-tsx" */),
  "component---src-view-landing-root-tsx": () => import("./../../../src/view/landing/Root.tsx" /* webpackChunkName: "component---src-view-landing-root-tsx" */),
  "component---src-view-privacy-root-tsx": () => import("./../../../src/view/privacy/Root.tsx" /* webpackChunkName: "component---src-view-privacy-root-tsx" */)
}

